
.sk-spinner[data-v-5d3d7a14] {
  height: 33px;
  padding-top: 7px;
  text-align: left;
  margin: 0;
}


.dropdown-list .top-dropdown-list, .dropdown-list .item-checkbox {
  margin:0px;
}
.dropdown-list .top-dropdown-list input {
  vertical-align: middle;
  margin-right: 3px;
}
.dropdown-list .top-dropdown-list span {
  vertical-align: middle;
}
.dropdown-list .item-checkbox input {
  vertical-align: middle;
}
.dropdown-list .item-checkbox span {
  vertical-align: middle;
}


.fa-check.active[data-v-037e60fb], .fa-user-circle-o.active[data-v-037e60fb] {
    color:green;
}
.fa-check.disabled[data-v-037e60fb], .fa-user-circle-o.disabled[data-v-037e60fb] {
    color:red;
}
td div.pretty[data-v-037e60fb] {
    margin:0;
}


form[name=site-switch-form] {
  display: flex;
}
form[name=site-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=site-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=site-switch-form] > div > div {
  flex-grow:1;
}
form[name=site-switch-form] > button {
  margin-left:10px;
}

